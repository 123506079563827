// BREAKPOINTS
$breakpoint-small:  640px;
$breakpoint-medium: 960px;
$breakpoint-normal: 1100px;
$breakpoint-large:  1300px;
$breakpoint-xlarge: 1600px;

// Add any breakpoint you add above to these lists as well!
$breakpoints: $breakpoint-small, $breakpoint-medium, $breakpoint-normal, $breakpoint-large, $breakpoint-xlarge;
$breakpoints-reverse:  $breakpoint-xlarge, $breakpoint-large, $breakpoint-normal, $breakpoint-medium, $breakpoint-small;

// CONTAINER
$container-max-width:        1900px;
$container-xsmall-max-width: 690px;
$container-small-max-width:  760px;
$container-large-max-width:  1200px;
$container-xlarge-max-width: 1800px !default;

$container-padding-horizontal:           15px;
$container-padding-horizontal-s:         40px;
$container-padding-horizontal-m:         55px;

// FARBEN

$global-color-ci-gruen:      rgb(0, 153, 138);
$global-color-ci-orange:     #FFA20D;
$global-color-ci-dunkelgrau: #313131;
$global-color-ci-mittelgrau: #9D9D9C;
$global-color-ci-hellgrau: #f3f3f3;
$global-color-ci-weiss:      #FFF;
$global-color-ci-schwarz:    #000;

$global-color-ci-bright:          $global-color-ci-weiss;
$global-color-ci-grau:            #808080;
$global-color-ci-medium-hellgrau: #e0e0e0;
$global-color-ci-blau:            #0065B8;

$global-color:                  $global-color-ci-dunkelgrau;
$global-emphasis-color:         $global-color-ci-dunkelgrau;
$global-muted-color:            $global-color-ci-mittelgrau;
$global-inverse-color:          $global-color-ci-weiss;
$inverse-global-color:          $global-inverse-color;
$inverse-global-muted-color:    $global-inverse-color;
$global-link-color:             $global-color-ci-gruen;
$global-link-hover-color:       $global-color-ci-gruen;
$navbar-nav-item-color:         $global-color-ci-weiss;

$form-select-option-color:          $global-color-ci-dunkelgrau;
$lightbox-background:               $global-color-ci-weiss;
$lightbox-toolbar-icon-hover-color: $global-color-ci-dunkelgrau;
$lightbox-button-hover-color:       $global-color-ci-dunkelgrau;

$table-row-active-background: $global-color-ci-hellgrau;

$tooltip-background: $global-color-ci-gruen;
$text-success-color: $global-color-ci-gruen;
$text-warning-color: $global-color-ci-orange;
$text-danger-color:  $global-color-ci-orange;
$text-marker-color:  lighten($global-color-ci-orange, 30%);

$base-em-color: $global-color-ci-dunkelgrau;

$card-default-title-color: $global-color-ci-gruen;

// BACKGROUND
$global-background:           $global-color-ci-weiss;
$global-primary-background:   $global-color-ci-gruen;
$global-secondary-background: $global-color-ci-dunkelgrau;
$global-muted-background:     $global-color-ci-hellgrau;
$global-success-background:   $global-color-ci-gruen;
$global-warning-background:   $global-color-ci-orange;
$card-hover-background: $global-color-ci-hellgrau;
$navbar-background:           $global-color-ci-gruen;

$navbar-dropdown-background: rgba($global-color-ci-weiss, 0.8);

// FONT
$global-base-font-size:    1rem;
$global-3xlarge-font-size: 2.1875rem;
$global-2xlarge-font-size: 1.8rem;
$global-xlarge-font-size:  1.5rem;
$global-large-font-size:   1.275rem;
$global-medium-large-font-size:  1.1rem;
$global-medium-font-size:  0.9375rem;
$global-small-font-size:   0.875rem;
$global-xsmall-font-size:  0.75rem;
$global-font-size:         20px;


$global-font-family:         'Myriad Pro Light', sans-serif;
$global-font-family-regular:   'Myriad Pro Regular', sans-serif;
$global-font-family-semibold:'Myriad Pro Semibold', sans-serif;

$global-font-weight-black:  900;
$global-font-weight-bold:   700;
$global-font-weight-medium: 500;
$global-font-weight-regular: 400;
$global-font-weight-light:  300;
$global-font-weight:        $global-font-weight-light;

$base-body-font-weight:     300;
$navbar-nav-item-font-size: 1rem;

$global-line-height: 1.6;

$text-large-font-size: $global-3xlarge-font-size;

// GUTTER
$global-gutter:        20px;
$global-small-gutter:  7px;
$global-medium-gutter: 35px;
$global-large-gutter:  65px;

// MARGIN
$global-margin: 10px;
$section-base-margin: 25px 0;
$global-medium-margin: 25px;
$global-large-margin: 50px;
$global-xlarge-margin: 65px;

//PADDING
$global-padding: $global-margin;
$section-base-padding: 25px 0;
$global-small-padding: 7px;
$global-medium-padding: 15px;
$global-large-padding: 30px;
$global-list-padding-left: 18px;

$padding-padding: 15px 20px;
$padding-padding-l: 28px 35px;

$padding-small-padding: $global-small-gutter;

$padding-large-padding: 28px 35px;
$padding-large-padding-l: 50px 65px;

// WIDTH
$global-category-card-width: 150px;
$global-category-card-width-medium: 180px;
$global-category-card-picture-max-width: 125px;

// BORDER
$global-border: $global-color-ci-grau;
$inverse-global-border: $global-color-ci-bright;

// LOGO
$logo-color:       $global-color-ci-bright;
$logo-hover-color: darken($global-color-ci-bright, 10%);

// ANCHOR
$base-link-hover-text-decoration: none;

// CLOSE
$alert-close-opacity: 1;

// TABLE
$table-small-cell-padding-vertical:              7px;
$table-small-cell-padding-horizontal:            12px;

// BOX SHADOW
$global-small-box-shadow: 0 2px 6px rgba(0,0,0,0.18);
$global-medium-box-shadow: 0 5px 12px rgba(0,0,0,0.18);
$global-large-box-shadow: 0 10px 15px rgba(0,0,0,0.26);
$global-xlarge-box-shadow: 0 20px 30px rgba(0,0,0,0.26);
$box-shadow-bottom-background:      $global-color-ci-dunkelgrau;
