.ws-iconbar {
  position: fixed;
  right: 0;
  top: 365px;
  width: 50px;
  background-color: $navbar-dropdown-background;
  .ws-iconbar-item {
    border-bottom: 8px solid transparent;
    &:hover {
      @include active-border();
    }
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}