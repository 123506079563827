.ws-section-category-nav, .ws-section-category-main-nav {
  background-position: 0 0, 0 100%, 0 0;
  background-repeat: no-repeat;
  padding-top: $global-small-gutter !important;
}
.ws-section-category-nav {
  @media (min-width: $breakpoint-medium) {
    background-image: linear-gradient($global-color-ci-hellgrau, $global-color-ci-hellgrau);
    background-size: 100% 200px;
    padding-top: $global-xlarge-margin !important;
    padding-bottom: $global-medium-margin !important;
  }
}
.ws-section-category-main-nav {
  background-image: linear-gradient($global-color-ci-hellgrau, $global-color-ci-hellgrau);
  background-size: 100% 200px;
  padding-top: $global-xlarge-margin !important;
  padding-bottom: $global-medium-margin !important;
}

.ws-section-spacer {
  padding: 17px 0 !important;
}