$search-navbar-font-size: $global-medium-font-size;
$search-color: $global-color-ci-weiss;
$search-placeholder-color: rgba($search-color, 0.7);
$search-icon-color: $search-color;

#ws-search-container {
  background-color: rgba(255,255,255,0.2);
  /* border-radius: 20px; */
  padding: 3px 5px 0px 15px;
  h3 {
    color: $global-color-ci-dunkelgrau !important;
  }
  li + li {
    margin-top: 0.5rem;
  }
  width: 100%;
  max-width: 450px;
}

.ws-search-ac-item-container {
  em {
    color: inherit;
    font-family: $global-font-family-semibold;
    font-style: normal;
  }
}