.uk-card-default {
  h3 {
    @include hyphensAuto;

    &.uk-card-title {
      font-family: $global-font-family-regular !important;
      font-size: $global-medium-font-size;
      color: $global-color-ci-dunkelgrau;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      @include hyphensAuto;
    }
  }
}

@mixin hook-card-default-header() {
  border-bottom: none;
  @include hyphensAuto;
}

.uk-card.ws-product-card {
  border-bottom: 8px solid transparent;
  &:hover {
    @include active-border();
  }

  background-color: $global-primary-background;

  .uk-card-media-top {
    background-color: $global-color-ci-bright;
  }

  .uk-card-body {
    padding-bottom: $global-gutter - 4px;
  }

  .uk-light .uk-text-meta {
    color: rgba($global-color-ci-bright, 0.7);
  }
}

@mixin hook-card-body() {
  .ws-product-card & {

  }

}

@mixin hook-card-default-footer() {
  border-top: none;
}

.ws-category-card {

  max-width: $global-category-card-width;
  min-width: $global-category-card-width;
  @media (min-width: $breakpoint-large) {
    min-width: $global-category-card-width-medium;
  }

  .uk-card-media-top picture, .uk-card-media-top picture img {
    max-width: $global-category-card-picture-max-width;
    padding: $global-small-gutter 0 $global-small-gutter $global-small-gutter;
  }

  border-bottom: 8px solid transparent;

  &.uk-card-hover:hover {
    @include active-border();
  }

  @include inactive-border();
}

.ws-flyout .ws-category-card {
  font-size: $global-small-font-size;
}

/*
li.uk-active + li.uk-active .uk-card {
  border: none !important;
}
*/