@mixin active-border() {
  border-bottom: 8px solid $global-color-ci-orange;
}
@mixin inactive-border() {
  border-bottom: 8px solid transparent;
}

@mixin border-dotted() {
  border-top: none !important;
  background-image: linear-gradient(to right, black 20%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 9px 2px;
  background-repeat: repeat-x;
}

@mixin hyphensAuto() {
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}