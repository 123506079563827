@mixin hook-navbar-dropdown() {
  box-shadow: none;
}

#ws-head-container {
  .uk-navbar-nav li.uk-active a {
    font-family: $global-font-family-semibold;
  }
}

.uk-logo > picture {
  position: relative;
  bottom: -6px;
}

#ws-offcanvas-nav {
  ul li a {
    font-size: $global-2xlarge-font-size;
  }

  ul li.uk-active a {
    color: $global-primary-background;
  }
}