.uk-dotnav .uk-card {
  @include inactive-border();
}

.uk-dotnav .uk-active .uk-card {
  @include active-border();
}

@mixin hook-dotnav-item() {
  display: block;
  box-sizing: border-box;
  overflow: unset !important;
  white-space: unset !important;

  width: auto !important;
  height: auto !important;
  text-indent: unset !important;
  border-radius: unset !important;
  background: unset !important;
  border: none !important;
}